import axios from 'axios'

export default {
  namespaced: true,
  state: {
    token: null,
    user: null
  },
  getters: {
    authorized(state){
        return ((state.token !== null) || (state.user !== null))
    },
    user(state){
        return state.user
    },
    token(state){
      return state.token
    }
  },
  mutations: {
    SET_TOKEN(state,token){
        localStorage.setItem('auth_token',token)
        state.token = token
    },
    SET_USER(state,user){
        state.user = user
    },
    RESET(state){
      localStorage.removeItem('auth_token')
      state.token = null
      state.user = null
    }
  },
  actions: {
    async attempt({commit},data){
        console.log(data.csrfToken)
        commit('SET_TOKEN',data.csrfToken)
        await axios.get(process.env.VUE_APP_BASE_URL+"/api/users/me",{
            headers: {
                'Authorization': 'Bearer '+data.csrfToken
            }
        }).then((resp)=>{
            commit('SET_USER',resp.data)
        }).catch(() => {
          commit('RESET')
        })
    }
  },
}