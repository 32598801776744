import { createRouter, createWebHistory } from 'vue-router'

// const obj = {
//   'category': 'Machine learning, Violence Detection, Fight Recognition, Surveillance Videos',
//   'completed': '2018',
//   'media': '<iframe class="embed-responsive-item" src="https://www.youtube.com/embed/M2Lj4sAfV44" allowfullscreen></iframe>',
//   'title': 'Violence Detection in Surveillance Video using Deep learning',
//   'description': `Violent action recognition has significant importance in developing automated video surveillance systems. Over last few years, violence detection such as fight activity recognition is mostly achieved through hand-crafted features
//   detectors. Some researchers also inquired learning based representation models. These approaches achieved high accuracies on Hockey and Movies benchmark datasets specifically designed for detection of violent sequences.
//   However, these techniques have limitations in learning discriminating features for videos with abrupt camera motion of Hockey dataset. Deep representation based approaches have been successfully used in image recognition
//   and human action detection tasks. This research work project used deep representation based model using concept of transfer learning for violent scenes detection to identify aggressive human behaviors. The result reports
//   that proposed approach is outperforming state-of-the-art accuracies by learning most discriminating features achieving 99.28% and 99.97% accuracies on Fight activity videos such as Hockey and Movies datasets respectively,
//   by learning finest features for the task of violent action recognition in videos.`

// }

const routes = [
  {
    path: '/',
    name: 'MainLayout',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home.vue')
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('@/views/About.vue')
      },
      {
        path: '/project/:slug',
        name: 'Project',
        component: () => import('@/views/Project.vue'),
        props: true
        
      },
      {
        path:'/contact',
        name: 'Contact',
        component: () => import('@/views/Contact.vue')
      }
    ]
  },
  {
    path:'/admin',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path:'/dash',
    name:'Dashboard',
    component: ()=> import('@/layouts/Dashboard.vue'),
    children: [
      {
        path: '/dash',
        component: () => import('@/views/dash/index.vue')
      },
      {
        path: '/dash/projects',
        name: 'project.index',
        component: () => import('@/views/dash/projects/index.vue')
      },
      {
        path: '/dash/project/create',
        name: 'project.create',
        component: () => import('@/views/dash/projects/create.vue')
      },
      {
        path: '/dash/project/:id',
        name: 'project.edit',
        props: true,
        component: () => import('@/views/dash/projects/create.vue')
      },
      {
        path: '/dash/nav',
        name: 'nav.create',
        component: () => import('@/views/dash/nav/create.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
